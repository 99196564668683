import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import './App.css';

const App = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [vaccineList, setVaccineList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Request microphone access when the component mounts
    const getMediaStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setMediaStream(stream);
      } catch (err) {
        console.error('Error accessing microphone', err);
        setError('Error accessing microphone. Please ensure you have granted permission.');
      }
    };

    getMediaStream();
  }, []);

  useEffect(() => {
    if (mediaStream) {
      let recorder;
      try {
        // Check if MediaRecorder is supported
        if (typeof MediaRecorder === 'undefined') {
          throw new Error('MediaRecorder is not supported in this browser. Please try using a different browser.');
        }

        // Set MIME type based on browser compatibility
        let mimeType;
        if (MediaRecorder.isTypeSupported('audio/webm')) {
          mimeType = 'audio/webm';
        } else if (MediaRecorder.isTypeSupported('audio/wav')) {
          mimeType = 'audio/wav';
        } else if (MediaRecorder.isTypeSupported('audio/mp3')) {
          mimeType = 'audio/mp3';
        } else {
          throw new Error('No supported audio format available.');
        }

        recorder = new MediaRecorder(mediaStream, { mimeType });
      } catch (e) {
        console.error('Error initializing MediaRecorder:', e);
        setError('Your browser does not support the available audio formats. Please try using a different browser or update your current browser.');
        return;
      }

      recorder.ondataavailable = async (event) => {
        if (event.data.size > 0) {
          // Convert recorded audio to a Blob
          const audioBlob = new Blob([event.data], { type: recorder.mimeType });

          // Send the audio to the backend for processing immediately after recording stops
          const formData = new FormData();
          formData.append('audio', audioBlob);

          try {
            setIsProcessing(true);
            setError(null);
            console.log('Sending audio to backend...');
            //const response = await fetch('http://127.0.0.1:5000/api/recognize-vaccines', {
            const response = await fetch('https://yavanna.org/api/recognize-vaccines', {
              method: 'POST',
              body: formData,
              headers: {
                'Accept': 'application/json',
              },
            });

            console.log('Response status:', response.status);
            if (!response.ok) {
              throw new Error(`Network response was not ok. Status: ${response.status}`);
            }

            const result = await response.json();
            // Update vaccine list with counts
            const updatedVaccineList = Object.entries(result.summary).map(([animal, vaccines]) => ({ animal, ...vaccines }));
            setVaccineList(updatedVaccineList);
          } catch (error) {
            console.error('Error sending audio to the backend:', error);
            if (error.message.includes('500')) {
              setError('A server error occurred while processing the audio. Please try again later.');
            } else {
              setError('An error occurred while sending audio to the backend. Please try again.');
            }
          } finally {
            setIsProcessing(false);
          }
        }
      };
      recorder.onstart = () => {
        setIsRecording(true);
      };
      recorder.onstop = () => {
        setIsRecording(false);
      };
      setMediaRecorder(recorder);
    }
  }, [mediaStream]);

  // Function to handle voice recording
  const handleRecord = () => {
    if (!mediaStream || !mediaRecorder) {
      console.error('No media stream or recorder available');
      alert('No media stream or recorder available');
      return;
    }

    if (isRecording) {
      // Stop recording
      mediaRecorder.stop();
    } else {
      // Start recording
      mediaRecorder.start();
    }
  };

  // Function to handle edit mode
  const handleEditMode = () => {
    setEditMode(true);
  };

  // Function to handle changes to the vaccine list
  const handleChange = (event, index, field) => {
    const updatedList = [...vaccineList];
    updatedList[index] = { ...updatedList[index], [field]: event.target.value };
    setVaccineList(updatedList);
  };

  // Function to save edited vaccine list
  const handleSave = () => {
    setEditMode(false);
  };

  // Function to extract vaccine list as CSV
  const handleExtractCSV = () => {
    const csvContent = "data:text/csv;charset=utf-8," +
      "Animal,Vaccine Name,Count\n" +
      vaccineList.map(vaccine => `${vaccine.animal},${Object.keys(vaccine).filter(key => key !== 'animal').map(key => `${key},${vaccine[key]}`).join('\n')}`).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv' });
    saveAs(blob, 'vaccine_list.csv');
  };

  return (
    <div className="app-container">
      <header className="app-header">
        <h1 className="app-title">Vaccine Recognition App</h1>
        <p className="app-description">This app can recognize vaccine names such as Foot and Mouth Disease (FMD), Haemorrhagic Septicaemia (HS), Black Quarter (BQ), Brucellosis, Theileriosis, Anthrax, IBR, and Rabies (Post bite therapy only). Please tap on the record button and say the name and number of vaccines along with the animal name - our AI will translate it to the list that you could save as CSV.</p>
      </header>
      <main className="app-main">
        <button className={`record-button ${isRecording ? 'recording' : ''}`} onClick={handleRecord} disabled={!mediaStream || isProcessing}>
          {isRecording ? 'Stop Recording' : 'Record Voice'}
        </button>

        {error && <div className="error-message">{error}</div>}

        {vaccineList.length > 0 && (
          <div className="vaccine-list-container">
            <h2 className="vaccine-list-title">Vaccine List</h2>
            {editMode ? (
              <div className="vaccine-list-actions">
                <button className="save-button" onClick={handleSave}>Save List</button>
              </div>
            ) : (
              <div className="vaccine-list-actions">
                <button className="edit-button" onClick={handleEditMode}>Edit List</button>
              </div>
            )}
            <ul className="vaccine-list">
              {vaccineList.map((vaccine, index) => (
                <li key={index} className="vaccine-list-item">
                  {editMode ? (
                    <div className="edit-vaccine-item">
                      <input
                        type="text"
                        value={vaccine.animal}
                        onChange={(event) => handleChange(event, index, 'animal')}
                        className="edit-vaccine-animal"
                      />
                      {Object.keys(vaccine).filter(key => key !== 'animal').map((vaccineName) => (
                        <div key={vaccineName}>
                          <input
                            type="text"
                            value={vaccineName}
                            onChange={(event) => handleChange(event, index, vaccineName)}
                            className="edit-vaccine-name"
                          />
                          <input
                            type="number"
                            value={vaccine[vaccineName]}
                            onChange={(event) => handleChange(event, index, vaccineName)}
                            className="edit-vaccine-count"
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <span className="vaccine-item-text">{vaccine.animal} - {Object.entries(vaccine).filter(([key]) => key !== 'animal').map(([name, count]) => `${name}: ${count} doses`).join(', ')}</span>
                  )}
                </li>
              ))}
            </ul>
            <button className="extract-csv-button" onClick={handleExtractCSV}>Extract CSV</button>
          </div>
        )}
      </main>
    </div>
  );
};

export default App;
